/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const injectVWOScript = () => {
  const vwoAppId = process.env.GATSBY_VWO_APP_ID;
  if (!vwoAppId) {
    return null;
  }

  const element = document.createElement("script");
  element.id = "vwoCode";
  element.setAttribute("type", "text/javascript");
  element.setAttribute("defer", "");
  element.innerHTML = `window._vwo_code=window._vwo_code || (function() {
    var account_id=${vwoAppId},
    version = 1.5,
    settings_tolerance=2000,
    library_tolerance=2500,
    use_existing_jquery=false,
    is_spa=1,
    hide_element='body',
    hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
    f=false,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\\s?(_vis_opt_exp_\\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\\d+_combi=(?:\\d+,?)+\\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{'+hide_element_style+'}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&e.setAttribute('nonce',vwoCodeEl.nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);var n=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(n?'&c='+n:''));return settings_timer}};window._vwo_settings_timer = code.init();return code;}());`;
  document.head.appendChild(element);
};

const injectMarketingScripts = () => {
  if (process.env.INCLUDE_MARKETING_SCRIPTS !== "true") {
    return null;
  }
  // all marketing prod scripts go here
  // NOTE: This sets `ub-emb-id` which we have classified as statistics as it is used for AB testing etc.
  // > Enables the website to make variations of their landing-page.
  // > This is used to minimize bounce-rates, which means that fewer users leave the page immediately.
  const element = document.createElement("script");
  element.src = "https://bae46957d1da4ad39e84912610a75c6d.js.ubembed.com";
  element.setAttribute("data-cookieconsent", "statistics");
  element.setAttribute("type", "text/plain");
  element.setAttribute("defer", "");
  document.body.appendChild(element);
};

const injectUtmStasher = () => {
  const source = process.env.GATSBY_UTM_STASH_SOURCE_URL;
  if (!source) {
    return null;
  }

  const element = document.createElement("script");
  element.src = source;
  element.setAttribute("data-cookieconsent", "ignore");
  element.setAttribute("defer", "");
  document.body.appendChild(element);
};

const storeQueryParams = () => {
  const queryParams = window.location.search;
  if (queryParams) {
    document.cookie = `queryParams=${queryParams}; max-age=${
      60 * 24 * 60 * 60
    }`;
  }
};

export const onClientEntry = () => {
  window.onload = () => {
    storeQueryParams();
  };
};

export const onInitialClientRender = () => {
  injectUtmStasher();
  injectMarketingScripts();
  injectVWOScript();
};
